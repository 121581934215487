.outerRoot {
  padding: 16px;
  background-color: #fff;
}

.signInRoot {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  padding: 16px 20px;
  background: #f2f8fb;
  border-radius: 16px;

  .header {
    /* H5/Caption/Caption-Sem */
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
    color: var(--neutral-color-text-06121212, #121212);
    text-align: center;
  }
}
