/* stylelint-disable font-family-no-missing-generic-family-keyword */
.textOverflow {
  position: relative;
  display: -webkit-box;
  overflow: hidden;

  /* WEB/Body/Body1-regular */
  font-family: "SF Pro Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  // color: var(--neutral-color-text-05515151, #515151);
  color: var(--text-icon-063-b-3-c-3-c, #3b3c3c);
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  &.open {
    -webkit-line-clamp: 99999;
  }
}

.expandRoot {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 10px;
  background-color: #fff;

  .btnText {
    color: #1c70ee;
  }
}
