/* stylelint-disable font-family-no-missing-generic-family-keyword */
.swiperSlideItem {
  display: flex !important;
  flex-direction: row;
  gap: 8px;
  width: 220px !important;
  height: auto !important;
  padding: 12px;
  margin-right: 12px !important;
  overflow: hidden;

  img {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    background: #fff;
    border: 0.4px solid var(--line-border-02-f-4-f-5-f-6-secondary, #f4f5f6);
    border-radius: 6px;
  }

  .content {
    display: flex;
    flex: 1;
    align-items: center;
    margin-left: 10px;
    overflow: hidden;
  }

  .name {
    display: -webkit-box;
    overflow: hidden;
    font-family: "SF Pro Display";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--neutral-color-text-06121212, #121212);
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    white-space: normal;
    -webkit-box-orient: vertical;
  }

  .desc {
    overflow: hidden;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    color: #7d7d7d;
    text-overflow: ellipsis;
  }
}

.antDrawer {
  padding: 0;

  :global {
    /* stylelint-disable-next-line selector-class-pattern */
    .ant-drawer-body {
      max-height: 70vh;
      padding: 16px;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .ant-drawer-content-wrapper {
      height: auto !important;
      max-height: 70vh;
      overflow: hidden;
      border-radius: 12px 12px 0 0;
    }
  }
}

.closeIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.modalContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .logoImg {
    width: 50px;
    height: 50px;
    margin-top: 8px;
    border-radius: 8px;
  }

  .title {
    overflow: hidden;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    color: var(--black-800121212, var(--neutral-color-text-06121212, #121212));
  }

  .desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--black-700515151, var(--neutral-color-text-05515151, #515151));
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    width: 100%;
    margin-top: 8px;
  }

  .imgItemWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
  }

  .imgItem {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(30%);
    height: 84px;
    overflow: hidden;
    border: 1px solid #ddd;

    .img {
      max-width: 100%;
      max-height: 100%;

      // object-fit: cover;
    }
  }
}
