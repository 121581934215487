/* stylelint-disable font-family-no-missing-generic-family-keyword */
.sectionHeader {
  font-family: "SF Pro Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;

  /* 144.444% */
  color: #000;
}

.sectionSecondaryHeader {
  /* WEB/Heading/H6 */
  font-family: "SF Pro Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  /* 150% */
  color: #121212;
}

.sectionRoot {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
  white-space: pre-wrap;

  // align-items: flex-start;

  // padding: 16px 20px;
}