// .outFilterRoot {
//   height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
// }

.filterRoot {
  display: flex;
  padding: 0;
  overflow: hidden;
  overscroll-behavior: contain;
  border-radius: 16px 16px 0 0;

  :global {
    /* stylelint-disable-next-line selector-class-pattern */
    .ant-drawer-body {
      padding: 0 !important;
    }
  }
}

.filterWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.filterHeader {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 20px 16px 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  color: var(--neutral-color-text-06121212, var(--neutral-color-text-06121212, #121212));
  text-align: center;

  .closeWrapper {
    position: absolute;
    right: 20px;
  }
}

.filterItemsRoot {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  padding: 8px 20px 20px;
  overflow: auto;
}

.filterItemRoot {
  padding: 0 0 16px;

  // border-top: 1px solid #f6f6f6;
}

.filterItemHeader {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: var(--neutral-color-text-06121212, var(--neutral-color-text-06121212, #121212));
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 0 12px;

  // gap: 12px;

  .filterItem {
    display: flex;
    gap: 12px;
    align-items: center;
    width: calc((100% - 12px) / 2);
    padding: 8px 0;
    overflow: hidden;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #515151;
  }
}

.bottomActions {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  background: #fff;
  border-top: 1px solid #f6f6f6;

  > div {
    flex: 1;

    // height: 40px;
    // padding: 12px 32px;
    // font-size: 14px;
    // font-weight: 500;
    // border-radius: 24px;
  }
}
