@use './base';
@import 'styles/mixins';

.container {
  width: 100%;
  margin: 0 auto;
  background: #F5F7FB;
}

.company_info_wrapper {
  display: flex;
  justify-content: space-between;
}

.jobs_item {
  width: 346px;
  padding: 16px;
  font-family: 'Product Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #c9cdd4;
  letter-spacing: 0.0075em;
  background: #fff;
  border: 1px solid #F2F3F5;

  // height: 91px;
  border-radius: 8px;
  transition: all 0.3s linear;

  &:hover {
    color: #fff;
    border: 1px solid #2378e5;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);

    .chat_now {
      color: #fff;
      opacity: 1;
    }

    .salary {
      opacity: 0;
    }
  }

  .header_box {
    display: flex;
  }
}


.jobs_item_layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab_layout {
  width: 1366 - 115 - 115px;
  margin: 0 auto;

  @include base.respond-to(phone) {
    width: 100%;
    padding-left: 0.04rem;
  }
}

.header {
  position: relative;
  width: 100%;
  min-height: 140px;
  padding-top: 36px;
  padding-bottom: 36px;
  background: linear-gradient(100deg, #DAEDFC 3.52%, #EFECFE 96.89%);

  @include base.respond-to(phone) {
    width: 100%;
    height: auto;
    padding: 0.36rem 0.2rem;
    margin-bottom: 0.32rem;
    overflow: visible;
  }
}

.header_info {
  position: relative;
  z-index: 3;

  // padding-top: 88px;

  display: flex;
  width: 1366px;
  padding-left: 117px;
  margin: 0 auto;

  @include base.respond-to(phone) {
    align-items: center;
    width: 100%;
    padding-left: 0;
  }
}

.header_logo {
  margin-right: 20px;

  @include base.respond-to(phone) {
    position: relative;
    flex-shrink: 0;

    // position: absolute;
    // z-index: 100;
    // bottom: -0.24rem;
    // left: 0.2rem;
    width: 0.49rem;
    height: 0.49rem;
    margin-right: 0.2rem;
  }
}

.header_logo_img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 16px;

  @include base.respond-to(phone) {
    width: 0.68rem;
    height: 0.68rem;
    border: 1px solid var(--neutral-color-border, #e5e6eb);
    border-radius: 0.13rem;
  }
}


.header_title_num_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
}

.header_title_right {
  position: absolute;
  top: 16px;
  right: 100px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.header_title_num {
  margin-right: 5px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  color: #121212;
  letter-spacing: 0.24px;
}

.header_title_string {
  position: relative;
  top: 2px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  color: #7D7D7D;
  letter-spacing: 0.15px;
}

.header_title {
  max-width: 800px;
  margin-top: 6.5px;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #121212;

  @include base.respond-to(phone) {
    margin-top: 0;
    font-size: 0.2rem;

    // width: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.25rem;
    color: #fff;
  }
}

.header_subtitle {
  display: flex;
  margin-top: 12px;
  font-family: 'Product Sans';
  font-family: "Product Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #515151;

  @include base.respond-to(phone) {
    flex-wrap: wrap;
    margin-top: 0.08rem;
    margin-bottom: 0;
    font-family: "Product Sans";
    font-size: 0.13rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.2rem;
    color: var(--neutral-color-text, #86909c);
  }
}

.header_subtitle_item {
  @include base.split;

  flex-shrink: inherit;
  font-size: 16px;
  color: #515151;
}

.header_benefit_wrapper {
  display: flex;
  flex-wrap: wrap;

  @include base.respond-to(phone) {
    padding-bottom: 0.1rem;
    margin: 0 0.2rem;
  }
}

.header_benefit_item {
  flex-direction: row;
  gap: 3px;
  align-items: center;
  padding: 7px 6px;
  margin-right: 4px;
  margin-bottom: 5px;
  font-family: 'Product Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.0075em;
  background: rgba(139, 139, 139, 0.8);
  border-radius: 5px;

  @include base.respond-to(phone) {
    margin-top: 10px;
    margin-right: 0.04rem;
    margin-bottom: 0.05rem;
    margin-bottom: 16px;
    font-family: "Product Sans";
    font-size: 0.13rem;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    line-height: 0.18rem;
    color: var(--neutral-color-text, #4e5969);
    text-align: center;
    background: var(--neutral-color-fill, #f7f8fa);
  }
}

.split {
  width: 100%;
  border-bottom: 1px solid var(--neutral-color-border, #e5e6eb);
}

.header_mask {
  position: absolute;
  inset: 0;
  z-index: 2;
  background: linear-gradient(180deg, rgba(7, 7, 7, 0.15) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.header_title_verified {
  position: relative;
  top: 5px;
  margin-left: 20px;

  @include base.respond-to(phone) {
    margin-left: 0.1rem;
  }
}

.footer {
  height: 85px;
  line-height: 85px;
  text-align: center;
}

.jobs_chat_now_container {
  position: relative;
  width: fit-content;
  min-width: 110px;
  padding-bottom: 10px;
}

.salary {
  font-family: 'Product Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  color: #2378e5;
  letter-spacing: 0.0075em;
}

.jobs_content {
  display: flex;
  align-items: center;
}

.jobs_item_title {
  display: block;
  flex: 1;
  width: 0;
  height: 23px;
  margin-bottom: 10px;
  overflow: hidden;
  font-family: 'Product Sans';
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #1d2129;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: #1d2129;
  }
}

.chat_now {
  position: absolute;
  bottom: 6px;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 73px;
  min-height: 28px;
  padding: 0 8px;
  color: #fff;
  white-space: nowrap;
  background: #2378e5;
  border-radius: 8px;
  opacity: 0;
  transition: all 0.3s linear;
}

.header_right {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  color: #7D7D7D;
  cursor: pointer;

  &:hover {
    color: #2378E5;

    .arrow {
      border-left-color: #2378E5;
    }
  }
}

.arrow {
  width: 0;
  height: 0;
  margin-left: 5px;
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #7D7D7D;
}

.more_job {
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  color: #1d2129;
  text-decoration-line: underline;
  letter-spacing: 0.0075em;
}


// @include msd {
.container {
  img {
    font-size: 0;
    background-color: #7D7D7D;
    border-radius: 0;
    border-radius: 9px;
  }

  font-size: 16px;
  line-height: 22px;
  color: #515151;

  .coverPic {
    width: 100%;
    height: auto;
  }

  .mobileHeader {
    padding: 28px 16px 20px;
    background: radial-gradient(67.36% 69.53% at 15.11% 3.13%, #5E4218 0%, #1B1307 100%);

    &::after {
      position: absolute;
      left: 0;
      width: 100%;
      height: 65px;
      content: '';
      background-image: url('./components/assets/header_bottom_border.png');
      background-repeat: no-repeat;
      background-size: 100%;
    }

    .info {
      display: flex;
      justify-content: flex-start;

      .companyName {
        /* 超出部分显示省略号 */
        display: -webkit-box;
        width: 100%;

        /* 防止内容换行 */
        overflow: hidden;
        color: #FDFDFE;

        /* 隐藏溢出的内容 */
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        white-space: pre-wrap;

        /* 显示两行 */
        -webkit-box-orient: vertical;
      }

      h1 {
        height: 48px;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        color: #fff;
      }

      .logo {
        width: 48px;
        height: 48px;
        margin-right: 12px;

        img {
          width: 48px;
          height: 48px;
        }
      }

    }

    .subtitle {
      padding-top: 20px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      color: #D5CCC1;

      span {
        position: relative;
        padding-right: 4px;

        // &::after {
        //   content: ' ';
        //   width: 3px;
        //   height: 3px;
        //   border-radius: 50%;
        //   position: absolute;
        //   left: -8px;
        //   top: 8px;
        //   background-color: #515151;
        // }

        &:first-of-type {
          &::after {
            display: none;
          }
        }
      }

      .itemRightIcon {
        vertical-align: middle;
      }

      .more {
        float: right;
        color: #D5CCC1;

        .moreIcon {
          margin-left: 4px;
          vertical-align: middle;
        }
      }
    }

    .tagGroup {
      width: 100%;
      margin-top: 12px;
      overflow-x: auto;
      word-break: keep-all;
      white-space: nowrap;

      .tagItem {
        display: inline-block;
        padding: 4px 10px;
        margin-right: 6px;
        margin-bottom: 4px;
        font-size: 12px;
        color: #D5CCC1;
        word-break: keep-all;
        white-space: nowrap;
        background: #493D34;
        border-radius: 4px;
      }
    }
  }

  // .mobileHeaderBottomBorder {
  //   height: 51px;
  //   background-image: url('./components/assets/header_bottom_border.png');
  //   background-color: #1B1307;
  //   background-repeat: no-repeat;
  //   background-size: 100%;
  // }
}

// }