.pagination {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;

  .paginationItemBase {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    min-height: 36px;
    max-height: 36px;
    padding: 4px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 10px;
  }

  .paginationItemNumber {
    background: #fff;
    border: 1.125px solid var(--neutral-color-line-03-e-4-e-4-e-4, #e4e4e4);
    border-radius: 10px;

    /* Normal Shadow Styles/Normal Shadow-md */
    box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);

    &[data-checked="true"] {
      color: #fff;
      background: #1c70ee;

      /* Normal Shadow Styles/Normal Shadow-md */
      box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
    }

    &[data-checked="false"]:hover {
      color: #2378e5;
      border: 1px solid #2378e5; // var(--brand-color-052378-e-5, #);
    }
  }

  .actionBtn {
    padding: 4px;
    cursor: pointer;

    &.prePageBtn {
      svg {
        transform: rotate(180deg);
      }
    }

    border: none;

    &[data-disabled="false"]:hover {
      background-color: #e8e8ea;
    }

    &[data-disabled="true"] {
      cursor: not-allowed;

      svg path {
        fill: #b8b8b8;
      }
    }
  }
}
