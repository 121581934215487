.cultureRoot {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.benefitsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.benefitItem {
  display: flex;
  gap: 12px;

  .benefitItemTitle {
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--neutral-color-text-06121212, #121212);
  }

  .benefitItemDesc {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--neutral-color-text-047-d-7-d-7-d, #7d7d7d);
  }
}
