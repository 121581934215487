/* stylelint-disable custom-property-pattern */
.jobItemRoot {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  justify-content: center;
  width: 100%;
  padding: 16px 24px;
  margin: 0 2px;
  overflow: hidden;
  cursor: pointer;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 16px;

  /* Normal Shadow Styles/Normal Shadow-md */
  box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);

  .title {
    /* WEB/Heading/Subtitle1-semibold */
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
    color: #000;
  }

  .chatBtnWrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    background-color: #fff;
    opacity: 0;
  }

  &:hover {
    border: 1px solid var(#1c70ee);
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.2);

    .title {
      color: #1c70ee;
    }

    .chatBtnWrapper {
      z-index: 1;
      opacity: 1;
    }
  }

  .jobTagRoot {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .jobTagItem {
    display: flex;
    height: 24px;
    padding: 0 8px;

    /* WEB/Heading/Subtitle3-medium */
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: var(--Global-Alpha-Black-Black, #000);
    background: var(--line-border-01-f-8-f-9-fa-primary, #f8f9fa);
    border-radius: var(--Radius-md-8-BTN, 8px);
  }

  .line {
    width: 100%;
    height: 1px;
    margin: 16px 0;
    background-color: #ebeef0;
  }

  .jobItemBottomWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    overflow: hidden;
  }

  .nameWrapper {
    // display: flex;
    gap: 8px;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .name {
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    color: #000;
  }

  .position {
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    color: var(--neutral-color-text-05515151, #515151);
  }

  .nameDot {
    position: relative;
    display: inline-block;
    height: 6px;
    margin: 0 8px;

    &::after {
      position: absolute;
      width: 2.5px;
      height: 2.5px;
      content: " ";
      background-color: #515151;
      border-radius: 50%;
    }
  }

  .avatar {
    position: relative;
    flex-shrink: 0;
    width: 36px;
    height: 36px;

    // overflow: hidden;
    border-radius: 50%;

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 50%;
    }

    .statusWrapper {
      position: absolute;
      right: -5px;
      bottom: 0;
      display: inline-block;
    }
  }
}

.statusRoot {
  display: flex;
  gap: 4px;
  align-items: center;

  &.online {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    color: #34c759;
  }

  .offline {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    color: var(--neutral-color-text-03-b-8-b-8-b-8, #b8b8b8);
  }
}
