.companyDetailCard {
  position: relative;
  background-color: #fff;

  // background: var(--background-neutral-bg-01-f-8-f-9-fa-primary, #f8f9fa);
  border-radius: 16px;
}

.cardTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  overflow: hidden;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: var(--main-color-black-black-01121212, #121212);
  text-overflow: ellipsis;

  :global {
    .cardTitleLinkText {
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: var(--brand-color-052378-e-5, #2378e5);
    }
  }
}

.wrapper {
  padding: 16px;
  background: var(--background-neutral-bg-01-f-8-f-9-fa-primary, #f8f9fa);
  border-radius: 16px;
}
