/* stylelint-disable selector-max-class */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable max-nesting-depth */
/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable scss/at-mixin-pattern */
@use "./base";
@import "styles/mixins";

.container {
  width: 100%;
  margin: 0 auto;
  background: #f5f7fb;
}
