@use '../../base';
@import 'styles/mixins';

.company_job_list {
  margin-top: 13px;
  bottom: 0;
  background: #fff;

  .line {
    width: 37px;
    height: 3px;
    border-radius: 22px;
    background: #BBC6E4;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 10px;
  }

  .header {
    height: 68px;
    padding: 10px 24px 0;
    background: linear-gradient(177deg, #DAE6FF 0.87%, #FFF 96.96%);
    display: flex;
    align-items: center;


    .menu {
      color: #7D7D7D;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.15px;
      margin-right: 28px;
      position: relative;

      sup {
        color: #7D7D7D;
      }

      &.menuActive {
        color: #121212;

        &::after {
          content: ' ';
          width: 11px;
          height: 5px;
          border-radius: 6px;
          position: absolute;
          left: 50%;
          bottom: -6px;
          transform: translateX(calc(-50% - 10px));
          background-color: #2378E5;
        }
      }
    }
  }

  .company_job_list_wrap {
    height: calc(100% - 68px);
    background: #F4F7FA;
  }
}