.cultureAndLifeItem {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 6px 10px;
  background: #fff;
  border: 1.2px solid var(--line-border-02-f-4-f-5-f-6-secondary, #f4f5f6);
  border-radius: 8px;

  /* Normal Shadow Styles/Normal Shadow-md */
  box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
  font-size: 16px;
  font-weight: 500;
  font-family: "SF Pro Display";
}
