@use '../../base';
@import 'styles/mixins';

.job_card_list {
  margin-top: 10px;
  .job_card_list_filter {
    display: flex;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 50;
    padding: 13px 16px 17px;
    background: #F4F7FA;
    .filter_item {
      padding: 0 8px;
      margin-right: 32px;
      cursor: pointer;
      .filter_name {
        display: inline-block;
        margin-right: 6px;
        font-size: 14px;
        font-weight: 500;
        color: #515151;
      }
      .filter_name_active {
        color: #121212;
      }
    }
  }
  .job_card_list_wrapper {
    // margin-top: 10px;
    .job_card {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      overflow: hidden;
      transition: all 0.3s linear;
      padding: 20px 0;
      border-bottom: 1px solid #f2f3f5;
      background: #fff;
      width: calc(100vw - 20px);
      margin: 0 auto;
      padding: 12px 10px;
      border-radius: 8px;
      margin-bottom: 8px;
      .job_card_wrapper {
        width: 100%;
      }
  
      .jobcard_salary_wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        white-space: nowrap;
        padding-bottom: 0px;
        margin-top: 8px;
  
        .salary {
          flex: 1;
          color: #2378E5;
          text-align: left;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 0.08rem;
          height: 34px;
          line-height: 34px;
          @include text_overflow;
        }

        .chat_now {
          display: flex;
          justify-content: center;
        }
      }

      .job_title {
        width: 100%;
        color:#1D2129;
        font-size: 16px;
        font-weight: 600;
        vertical-align: middle;
        line-height: normal;
        @include text_overflow;
      }

      .job_type {
        margin-top: 8px;
        font-size: 14px;
      }

      .urgent_label {
        display: inline-flex;
        padding: 2px 3px;
        justify-content: center;
        align-items: center;
        color: #fffefe;
        font-size: 10px;
        font-weight: 700;
        border-radius: 4px;
        letter-spacing: 0.075px;
        background: linear-gradient(98deg, #F64A88 -2.73%, #F56A6A 95.77%);
        box-shadow: 0px 4px 13px 0px rgba(255, 60, 60, 0.10);
        margin-right: 8px;
      }

      .job_benefits {
        // @keyframes overflow-x-scroll {
        //   0% {
        //     transform: translateX(0); /* 初始位置 */
        //   }
        //   100% {
        //     transform: translateX(calc(-100% / 2)) /* 滚动到一半的位置 */
        //   }
        // }
        width: 100%;
        overflow-x: auto;
        word-break: keep-all;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        // animation: overflow-x-scroll 10s linear infinite;

        .job_tag {
          margin-right: 8px;
          margin-top: 6px;
          padding: 4px 8px;
          border-radius: 4px;
          background: #F6F6F6;
          color: #515151;
          font-size: 14px;
          word-break: keep-all;
          white-space: nowrap;
          display: inline-block;
        }
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  padding-bottom: 60px;

  @include base.respond-to(phone) {
    padding-top: 0.2rem;
    padding-bottom: 0.3rem;
  }

  :global {
    @include base.respond-to(phone) {
      .MuiPaginationItem-icon {
        font-size: 0.24rem;
      }

      .MuiPaginationItem-sizeMedium {
        font-size: 0.14rem;
      }
    }
  }
}

.job_card_check_list {
  height: 46vh;
  overflow: auto;
  --border-top: 0;
  --border-bottom: 0;
  :global {
    .adm-check-list-item-active {
      color: #2378E5;
    }
    .adm-list-item {
      padding-left: 0;
    }
  }
}

.fr {
  float: right;
}

.close_icon {
  vertical-align: middle;
  float: right;
}