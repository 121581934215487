/* stylelint-disable font-family-no-missing-generic-family-keyword */
.mainHeader {
  /* WEB/Heading/H4 */
  font-family: "SF Pro Display";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  color: #000;
}

.secondaryHeader {
  /* WEB/Caption/Caption-Bold */
  font-family: "SF Pro Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  color: #000;
}
