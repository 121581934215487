/* stylelint-disable font-family-no-missing-generic-family-keyword */
.cultureRoot {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.benefitsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.benefitItem {
  display: flex;
  gap: 12px;

  .benefitItemTitle {
    /* WEB/Heading/Subtitle3-medium */
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    color: var(--neutral-color-text-06121212, #121212);
  }

  .benefitItemDesc {
    /* WEB/Body/Body2-regular */
    font-family: "SF Pro Display";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    color: var(--neutral-color-text-047-d-7-d-7-d, #7d7d7d);
  }
}
