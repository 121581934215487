/* stylelint-disable font-family-no-missing-generic-family-keyword */
.baseInfoList {
  display: flex;
  flex-direction: column;
  gap: 12px;
  line-height: 24px;

  .baseInfoListItem {
    display: flex;
    flex-direction: row;
  }

  .itemName {
    flex-shrink: 0;
    width: 190px;
    font-size: 16px;
    font-weight: 600;
    color: var(--neutral-color-text-06121212, #121212);
  }

  .itemValue {
    flex: 1;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: var(--neutral-color-text-06121212, #121212);
  }
}

.companyDescription {
  position: relative;
  display: -webkit-box;
  overflow: hidden;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;

  /* 175% */
  color: var(--text-icon-063-b-3-c-3-c, #3b3c3c);
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;

  .expandBtn {
    position: absolute;
    right: 0;
    bottom: 0;
    padding-left: 10px;
    cursor: pointer;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, #fff 7.5%);

    .text {
      color: #2378e5;
    }

    // &:hover {
    //   font-weight: 400;
    //   color: var(--brand-color-052378-e-5, #2378e5);
    // }
  }

  &.expand {
    display: block;

    .expandBtn {
      position: static;
    }
  }
}

.companyAlbum {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  .companyAlbumItem {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc((100% - 24px) / 3);
    height: 150px;
    overflow: hidden;

    // border: 1px solid #ddd;
    border-radius: 12px;
  }

  .albumImage {
    // max-width: 100%;
    // max-height: 100%;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.businessInfoOutWrapper {
  background: #f7f8fa;
  border-radius: 8px;
}

.businessInfo {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  gap: 30px 20px;
  align-items: center;
  align-items: flex-start;
  align-self: stretch;
  padding: 20px;

  .businessInfoListItem {
    width: calc(50% - 10px);
  }

  .businessInfoListItemName {
    font-size: 14px;
    font-weight: 400;
    line-height: 23.287px;
    color: var(--text-37-d-7-d-7-d, #7d7d7d);
  }

  .businessInfoListItemValue {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.287px;
    color: var(--text-5121212, #121212);
  }
}

.overviewTable {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .label {
    display: inline-block;
    flex-shrink: 0;
    width: 190px;

    /* H5/Caption/Caption-Sem */
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;

    /* 137.5% */
    color: var(--neutral-color-text-06121212, #121212);
  }

  .value {
    display: inline-flex;
    align-items: center;

    /* WEB/Heading/Subtitle3-medium */
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    /* 150% */
    color: var(--neutral-color-text-06121212, #121212);
  }

  a.value:hover {
    text-decoration: underline;
  }

  .hasUnderline {
    text-decoration-line: underline;
    text-decoration-thickness: auto;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-underline-offset: auto;
    text-decoration-skip-ink: none;
  }
}

.mapWrapper {
  :global {
    .mapWrapper {
      overflow: hidden;
      border-radius: 12px;
    }
  }
}