/* stylelint-disable font-family-no-missing-generic-family-keyword */
.hotJobs {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  background: #fff;
  border-radius: 16px;

  a {
    position: relative;
  }
}

.hotJobCardRoot {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: center;
  width: 324px;
  padding: 20px;
  background: #fff;
  border: 1.2px solid var(--line-border-02-f-4-f-5-f-6-secondary, #f4f5f6);

  // background: #fff;
  // border: 1px solid #fff;
  // border-radius: 16px;

  border-radius: 16px;

  /* Normal Shadow Styles/Normal Shadow-md */
  box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);

  .hotJobTitle {
    font-family: "SF Pro Display";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    color: #000;
  }

  .salaryWrapper {
    position: relative;
    height: 32px;
  }

  .applyBtnWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0;
  }

  &:hover {
    border-color: rgba(0, 102, 255, 0.5);
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.1);

    .hotJobTitle {
      color: #1c70ee;
    }

    .applyBtnWrapper {
      z-index: 1;
      opacity: 1;
    }
  }
}

.jobTags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  margin-top: 4px;

  .tagItem {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    padding: 0 8px;

    /* WEB/Heading/Subtitle3-medium */
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 175% */
    color: var(--text-icon-063-b-3-c-3-c, #3b3c3c);
    background: var(--line-border-01-f-8-f-9-fa-primary, #f8f9fa);
    border-radius: var(--Radius-md-8-BTN, 8px);
  }
}

.viewAllBtn {
  display: inline-flex;
  gap: 4px;
  align-items: center;
  margin: 0 auto;
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  color: var(--neutral-color-text-06121212, #121212);
  text-align: center;
  cursor: pointer;
}
