/* stylelint-disable custom-property-pattern */
/* stylelint-disable font-family-no-missing-generic-family-keyword */
.swiperSlideItem {
  display: flex !important;
  flex-direction: row;
  gap: 16px;
  width: 220px !important;
  height: auto !important;
  padding: 10px;
  background: var(--Harmonies-Turquoise-Turquoise-Lightest, #f2f9f7);
  border-radius: 12px;

  img {
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  .content {
    flex: 1;
    overflow: hidden;
  }

  .name {
    position: relative;
    overflow: hidden;

    /* WEB/Heading/Subtitle3-medium */
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    color: #000;
    text-overflow: ellipsis;
    letter-spacing: 0.15px;
    white-space: nowrap;
  }

  .position {
    overflow: hidden;

    /* WEB/Heading/Subtitle4-medium */
    font-family: "SF Pro Display";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    color: var(--neutral-color-text-05515151, #515151);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .replayLabel {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .status {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;

    /* WEB/Heading/Subtitle4-medium */
    font-family: "SF Pro Display";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    color: #34c759;
  }

  .offline {
    color: #b8b8b8;
  }
}
