/* stylelint-disable custom-property-pattern */
/* stylelint-disable font-family-no-missing-generic-family-keyword */
.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.addressName {
  font-size: 15px;
  font-weight: 600;
  color: var(--black-800121212, var(--neutral-color-text-06121212, #121212));
  letter-spacing: 0.15px;
}

.addressDetail {
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  color: var(--neutral-color-text-06121212, #121212);
}

.planeWrapper {
  display: flex;
  gap: 4px;
  align-items: center;
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  color: var(--Color-Brand-Standard, #1c70ee);
}
