.drawerWrapper {
  background-color: transparent !important;

  :global {
    .ant-drawer-header {
      display: none !important;
    }

    .ant-drawer-body {
      padding: 0 !important;
    }
  }
}
