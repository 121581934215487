.commCompanyDetailModal {
  //
  //
  //
  :global {
    .ant-modal-content {
      padding: 0 !important;
    }

    .ant-modal-close {
      display: none;
    }
  }
}
