/* stylelint-disable font-family-no-missing-generic-family-keyword */
.tabRoot {
  display: flex;
  background-color: #fff;

  .tabItem {
    display: flex;
    gap: 10px;
    align-items: center;
    height: 48px;
    padding: 2px 12px 0;

    /* WEB/Heading/Subtitle3-medium */
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    color: #000;
    text-align: right;
    border-bottom: 2px solid #fff;

    &[data-checked="true"] {
      font-weight: 600;
      border-color: #000;
    }
  }
}
