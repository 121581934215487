@use '../../base';

.section {
  position: relative;


  &:last-child {
    .split {
      opacity: 0 !important;
    }
  }

  @include base.respond-to(phone) {
    // margin: 0 20px;
    // padding: 24px 0;
    // border-bottom: 1px solid #E4E4E4;
  }
}

.title_layout {
  display: flex;
  margin-bottom: 16px;

  @include base.respond-to(phone) {
    margin-bottom: 16px;
  }
}

.title {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #121212;

  span {
    padding-left: 12px;
    cursor: pointer;
    position: relative;
    top: 2px;
  }

  @include base.respond-to(phone) {
    color: #121212;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 0.25rem;
  }
}

.tag {
  width: 38px;
  height: 18px;
  background: linear-gradient(97.86deg, #f74a88 -2.73%, #f56a6a 95.77%);
  box-shadow: 0px 4px 13px rgba(255, 60, 60, 0.1);
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;

  :global {
    span {
      line-height: 17px;
    }
  }
}

.section_content_wrapper {

  :global {
    #WorkingLocation {
      p {
        margin: 0;
        padding: 0;
      }
    }

    .mapWrapper {
      padding-top: 16px;
    }
  }

  @include base.respond-to(phone) {
    padding-bottom: 0rem;
  }
}

.split {
  padding: 48px 0 0 0;
  margin-bottom: 36px;
  border-bottom: 1px solid #E5E6EB;

  @include base.respond-to(phone) {
    border: none;
    margin-bottom: 0;
    padding: 0;
  }
}