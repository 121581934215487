@use '../../base';

.tab_content_wrapper {
  width: 100%;
  margin-right: 12px;
  border-radius: 8px;
  color: #515151;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  @include base.respond-to(phone) {
    padding: 0;
    border-radius: 0px;
  }
}

.mobile_album {
  width: auto;
  height: 80px;
  position: relative;
  border-radius: 5px;
  img {
    border-radius: 2px !important;
  }
}