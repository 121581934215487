@mixin text_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

@mixin respond-to($breakpoint) {
  @if $breakpoint ==phone {
    @media (max-width: 767px) {
      @content;
    }
  }

  @else if $breakpoint ==tablet {
    @media (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  }

  @else if $breakpoint ==desktop {
    @media (min-width: 992px) {
      @content;
    }
  }

  @else if $breakpoint ==large {
    @media (min-width: 1200px) {
      @content;
    }
  }
}

@mixin split {
  display: block;
  color: var(--neutral-color-text, #86909c);
  /* Regular/Body/Regular/medium */
  font-family: Product Sans;
  font-weight: 400;
  flex-shrink: 0;
  color: var(--neutral-color-text, #fff);
  /* Regular/Body/Regular/Small */
  font-family: Product Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &::after {
    content: '';
    font-size: 12px;
    height: 2px;
    width: 2px;
    margin: 0 8px;
    background: #515151;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    bottom: 4px;
  }

  &:last-child {
    &::after {
      content: '';
      display: none;
    }
  }
}