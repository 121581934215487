.jobFunctionRoot {
  display: flex;
  gap: 24px;
}

.jobFunctionWrapper {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 16px;
  padding: 0 16px;
}

.jonFunctionLabel {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: var(--neutral-color-text-06121212, #121212);
}

.jobFunctionItem {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--text-icon-063-b-3-c-3-c, #3b3c3c);
  white-space: nowrap;
  cursor: pointer;

  &.checked {
    color: #1c70ee;
  }
}

.filterRoot {
  position: relative;
  z-index: 3;
  display: flex;
  padding-left: 3px;
  margin: 24px 0 16px;

  :global {
    .wrapperSelectMut {
      .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
        background: #FFF;
        border: 1px solid #EBEEF0;
        box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.10);
      }
    }
  }

  .resetBtn {
    border: 1px solid #EBEEF0;

    &:hover {
      backdrop-filter: blur(3px);
      border: 1px solid #3385FF !important;
      box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.2) !important;
    }
  }
}

.jobsRoot {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  gap: 12px;
  padding: 0;
  background: none;
}

.jobNameWrapper {
  position: relative;
  display: flex;
  flex: 1;
  gap: 8px;
  align-items: center;

  .chatBtnWrapper {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0;
    transition: all 0.3s linear;
  }
}

.jobItem {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc((100% - 12px) / 2);
  min-height: calc(130px + 24px);
  padding: 12px 16px;
  cursor: pointer;
  background: #fff;
  border-radius: 8px;

  .jobItemTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    color: var(--neutral-color-text-06121212, #121212);
    text-decoration: none;

    &:hover {
      color: #136fd3;
    }
  }

  .splitter {
    height: 1px;
    margin: 12px 0;
    background: #f6f6f6;
  }

  &:hover {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);

    .chatBtnWrapper {
      opacity: 1;
    }
  }
}

.jobItemSalary {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: var(--brand-color-052378-e-5, #2378e5);
}

.jobItemTags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--neutral-color-text-047-d-7-d-7-d, #7d7d7d);

  .dot {
    display: inline-block;
    width: 2px;
    height: 2px;
    background-color: #7d7d7d;
    border-radius: 50%;
  }
}

.jobItemBottomWrapper {
  display: flex;
  gap: 8px;
  height: 24px;
  font-size: 14px;
  font-weight: 400;

  .dot {
    display: inline-block;
    width: 2px;
    height: 2px;
    background-color: #7d7d7d;
    border-radius: 50%;
  }
}

.chatNowBtn {
  min-width: 78px;
  padding: 7px 18px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  text-align: right;
  letter-spacing: 0.0075em;
  background: #2378e5;
  border-radius: 8px;
}

.jobItemAvatar {
  position: relative;

  &[data-isonline="true"] {
    &::after {
      position: absolute;
      right: -1px;
      bottom: -1px;
      width: 8px;
      height: 8px;
      content: " ";
      background-color: #34c759;
      border: 1px solid #fff;
      border-radius: 50%;
    }
  }

  img {
    width: 24px;
    height: 24px;
    border: 1px solid #34c759;
    border-radius: 50% 50% 50% 0;
  }
}

.jobItemRecruiterName {
  color: var(--neutral-color-text-05515151, #515151);
}

.jobItemRecruiterRole {
  color: var(--neutral-color-text-047-d-7-d-7-d, #7d7d7d);
}

.emptyNode {
  display: flex;
  flex-direction: column;

  // gap: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;

  /* WEB/Body/Body2-regular */
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  /* 157.143% */
  color: var(--global-neutral-14202021, #202021);
  text-align: center;
}