.benefitItem {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 4px 10px;
  border: 1.2px solid var(--line-border-03-eeeff-0-tertiary, #ebeef0);
  border-radius: 8px;
}
