.tabRoot {
  position: sticky;
  top: 0;
  z-index: 102;
  display: flex;

  // gap: 40px;
  align-items: flex-start;
  align-self: stretch;
  background-color: #fff;
  border-bottom: 1px solid var(--global-alpha-black-black-10, rgba(0, 0, 0, 0.1));

  .tabItem {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    // min-width: 0px;
    height: 48px;
    padding: 2px 20px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    color: #000;
    cursor: pointer;

    &[data-checked="true"] {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      border-bottom: 2px solid #000;
    }
  }
}
