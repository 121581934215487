/* stylelint-disable-next-line scss/dollar-variable-pattern */
$minWidthForPc: 1200px;

.commLayout {
  min-width: $minWidthForPc;
  // padding: 0 48px;
}

.commContent {
  min-width: 1104px;
  max-width: 1344px;
  margin: 0 48px;

  // @media screen and (max-width: 1200px) {
  //   max-width: 1104px;
  // }

  @media screen and (min-width: 1440px) {
    // max-width: 1344px;
    margin: 0 auto;
  }

  // @media screen and (min-width: 1920px) {
  //   max-width: 1624px;
  // }
}

.headerLayout {
  min-width: $minWidthForPc;
}

.footerLayout {
  min-width: $minWidthForPc;
}
