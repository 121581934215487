.applyBtn {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: 128px;
  height: 32px;
  padding: 2px 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  color: #fff;
  background: #1c70ee;
  border-radius: 8px;
}
