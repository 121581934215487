/* stylelint-disable selector-max-class */
/* stylelint-disable max-nesting-depth */
// Modal 全局样式覆盖
.modal {
  :global {
    .ant-modal-content {
      height: 497px;
      padding: 32px 24px !important;

      // Modal 头部样式
      .ant-modal-header {
        padding: 0 !important;
        margin: 0 !important;
      }

      // Modal 内容区域样式
      .ant-modal-body {
        padding: 0 !important;
      }

      // Modal 关闭按钮样式
      .ant-modal-close {
        top: 24px !important;
        right: 14px !important;
      }

      .ant-modal-title {
        font-family: "SF Pro Display";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        color: var(--text-icon-07121212, #111212);
      }

      // Radio 自定义样式
      // .ant-radio-wrapper {
      //   .ant-radio {
      //     .ant-radio-inner {
      //       background-color: #fff;
      //       border: 1px solid #d9d9d9;
      //       border-radius: 50%;

      //       &::after {
      //         display: none;
      //       }
      //     }

      //     // 选中状态
      //     &.ant-radio-checked {
      //       .ant-radio-inner {
      //         background: #1677ff;
      //         border-color: #1677ff;

      //         &::after {
      //           position: absolute;
      //           top: 50%;
      //           left: 50%;
      //           display: block;
      //           width: 5px;
      //           height: 9px;
      //           content: '';
      //           border: 2px solid #fff;
      //           border-top: 0;
      //           border-left: 0;
      //           opacity: 1;
      //           transition: none;
      //           transform: translate(-50%, -55%) rotate(45deg);
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}

.backIcon {
  cursor: pointer;
}

// 主选项列表样式
.mainOptions {
  padding: 8px 0;
  margin-top: 24px;

  .mainOptionItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    color: #111212;
    text-align: center;
    cursor: pointer;

    .arrowIcon {
      font-size: 18px;
      color: #999;
    }
  }
}

// 子选项容器样式
.subOptionsContainer {
  .modalHeader {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;

    .backIcon,
    .closeIcon {
      padding: 4px;
      font-size: 20px;
      color: #999;
      cursor: pointer;

      &:hover {
        color: #666;
      }
    }
  }

  // 子选项列表
  .subOptions {
    flex: 1;
    height: 350px;
    max-height: 400px;
    padding: 16px 0;
    overflow: auto;
    overflow-y: auto;

    .subOptionItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 16px;
      cursor: pointer;

      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px !important;
        height: 20px;
        margin-right: 8px;
        cursor: pointer;
        background: transparent;
        border: 1.6px solid #DBDFE4;
        border-radius: 24px;

        svg {
          margin: 0;
        }
      }

      .checked {
        background-color: #1C70EE;
        border-color: #1C70EE;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .subOptionContent {
        flex: 1;
        margin-left: 8px;
      }

      .subOptionTitle {
        font-family: "SF Pro Display";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 23px;
        color: #111212;
      }

      .subOptionDesc {
        font-family: "SF Pro Display";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #4B4C4D;
      }
    }
  }

  // 底部按钮
  .actionButtons {
    display: flex;
    gap: 8px;
    justify-content: center;
    padding: 16px;

    button {
      flex: 1;
      padding: 12px 24px;
      font-size: 14px;
      cursor: pointer;
      border-radius: 4px;
    }

    .cancelBtn {
      display: flex;
      flex: 1 0 0;
      gap: 6px;
      align-items: center;
      justify-content: center;
      height: 40px;
      padding: 12px 32px;
      font-family: "SF Pro Display";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      color: #06F;
      background: #fff;
      border: 1.2px solid #1C70EE;
      border-radius: 10px;

      // &:hover {
      //   color: #1677ff;
      //   border-color: #1677ff;
      // }
    }

    .confirmBtn {
      display: flex;
      flex: 1 0 0;
      gap: 6px;
      align-items: center;
      justify-content: center;
      height: 40px;
      padding: 12px 32px;
      font-family: "SF Pro Display";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      color: #FFF;
      background: #1C70EE;
      border: none;
      border-radius: 10px;

      &:disabled {
        cursor: not-allowed;
        background: rgba(28, 112, 238, 0.25);
      }

      // &:hover:not(:disabled) {
      //   background: #4096ff;
      // }
    }
  }
}

.reminderRoot {
  display: flex;
  flex-direction: column;
  padding: 20px 24px 32px;
  background: #f5f9ff;
  border-radius: 16px;

  .header {
    margin-top: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: var(--text-icon-054-b-4-c-4-d, #4b4c4d);
    text-align: center;
  }

  .bodyText1 {
    margin: 16px 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: var(--neutral-color-text-05515151, #515151);
  }

  .bodyText2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: var(--neutral-color-text-05515151, #515151);
  }

  :global {
    .aLikeText {
      // margin-left: 3px;
      text-decoration-line: underline;
      cursor: pointer;
    }

    .aLikeText:hover {
      color: #000;
    }
  }

  .list {
    margin-top: 0;
  }

  .listItem {
    display: flex;
    gap: 6px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: var(--neutral-color-text-05515151, #515151);
    list-style: none;
  }

  .dotWrapper {
    display: flex;
    align-items: center;
    height: 22px;
  }
}

.btnALike {
  /* H5/Caption/Caption-Med */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  /* 142.857% */
  color: var(--neutral-color-text-05515151, #515151);
}