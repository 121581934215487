.hiBossRoot {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.hiBossItem {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;
  width: calc(50% - 6px);
  padding: 16px 18px;
  cursor: pointer;
  background: var(--background-neutral-bg-01-f-8-f-9-fa-primary, #f8f9fa);
  border: 1px solid #f8f9fa;
  border-radius: 12px;

  &:hover {
    border: 1px solid var(--global-alpha-brand-brand-70, rgba(0, 102, 255, 0.7));
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.2);
  }

  img {
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    overflow: hidden;
    border: 1.5px solid var(--background-neutral-bg-02-f-3-f-5-f-6-secondary, #f3f5f6);
    border-radius: 50%;
    border-radius: 40px;
  }

  .avatar {
    position: relative;
  }

  .onlineWrapper {
    position: absolute;
    right: 1px;
    bottom: 6px;
    z-index: 1;
  }

  .name {
    overflow: hidden;
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: "SF Pro Display";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .position {
    overflow: hidden;

    /* WEB/Heading/Subtitle3-medium */
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    color: var(--text-icon-063-b-3-c-3-c, #3b3c3c);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .lastOnlineWrapper {
    overflow: hidden;

    /* WEB/Body/Body2-regular */
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: "SF Pro Display";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    color: var(--text-icon-04646566, #646566);
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.moreBtn {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--neutral-color-text-047-d-7-d-7-d, #7d7d7d);
  cursor: pointer;

  &.open {
    svg {
      transform: rotate(180deg);
    }
  }
}
