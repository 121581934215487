/* stylelint-disable font-family-no-missing-generic-family-keyword */
.headerRoot {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  align-self: stretch;
  padding: 0 12px 12px;
  background: var(--background-neutral-bg-01-f-8-f-9-fa-primary, #f8f9fa);
  border-radius: 0 0 16px 16px;

  .companyLogo {
    $size: 64px;

    position: relative;

    // top: -3px;
    width: $size;
    height: $size;
    background: #fff;
    border: 0.711px solid var(--line-border-02-f-4-f-5-f-6-secondary, #f4f5f6);
    border-radius: 12px;
  }

  .companyName {
    display: flex;
    gap: 12px;
    align-items: center;

    /* WEB/Heading/H5 */
    font-family: "SF Pro Display";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
    color: #000;
  }

  .displayName {
    margin-top: 6px;

    /* WEB/Body/Body2-regular */
    font-family: "SF Pro Display";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    color: #000;
  }

  .horLine {
    width: 100%;
    height: 0.5px;
    margin: 16px 0;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .jobAndBoss {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-top: 4px;
    font-family: "SF Pro Display";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #000;

    .unit {
      margin-left: 4px;
      font-weight: 400;
    }
  }
}

.emailAndPhoneRoot {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .item {
    display: flex;
    flex-direction: row;
    gap: 6px;
    font-family: "SF Pro Display";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #000;
  }

  .moreBtn {
    font-weight: 400;
  }
}

.drawerHeader {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 8px 10px;
  background: #fff;
  border-radius: 16px 16px 0 0;
}

.mobileCloseHolder {
  width: 40px;
  height: 4px;
  background: var(--black-500-b-8-b-8-b-8, #b8b8b8);
  border-radius: 30px;
}

.headerText {
  padding: 16px 20px;
  font-family: "SF Pro Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 144.444% */
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid var(--black-200-f-6-f-6-f-6-bg, #f6f6f6);
}

.contentRoot {
  height: 320px;
  overflow: auto;
  background-color: #fff;
}

.phoneItem {
  padding: 18px 20px;
  border-bottom: 1px solid var(--neutral-color-line-01-f-6-f-6-f-6, #f6f6f6);
}
