/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-max-class */
.selector {
  :global {
    /* stylelint-disable-next-line selector-class-pattern */
    .ant-select {
      height: 44px;
      background: #fff;
      backdrop-filter: blur(3px);
      border: 1px solid #ebeef0;
      border-radius: 12px;

      /* Normal Shadow Styles/Normal Shadow-md */
      box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
    }

    .ant-select.ant-select-outlined .ant-select-selector {
      background: none !important;
      border: none !important;
      border-radius: 12px !important;
    }

    .ant-select.ant-select-outlined:hover {
      backdrop-filter: blur(3px);
      border: 1px solid var(--brand-color-primary-043385-ff, #3385ff);
      box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.2);
    }

    .ant-select.ant-select-outlined:hover .ant-select-selector {
      background: #fff !important;
    }

    .ant-select.ant-select.activeLabel .selectLabel {
      color: #121212 !important;
    }

    .ant-select.ant-select-outlined:hover .selectLabel {
      color: #2378e5 !important;
    }
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
  height: 44px;
  cursor: pointer;
  background: #fff;
  border: 1px solid var(--global-alpha-black-black-5, rgba(0, 0, 0, 0.05));
  border-radius: 12px;
  box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);

  input,
  input::placeholder {
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: #000;
  }

  &:focus,
  &:focus-within,
  &:hover {
    // 1px solid var(--global-alpha-black-black-5, rgba(0, 0, 0, 0.05))
    border-color: rgba(0, 0, 0, 0.05) !important;

    // backdrop-filter: blur(3px);
    // border: 1px solid #1C70EE;
    box-shadow: none !important;
  }
}
